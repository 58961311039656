
import { defineComponent } from "vue";
import Base from "@/components/taskManagement/Base.vue";
import CarryOut from "@/components/taskManagement/CarryOut.vue";
import Done from "@/components/taskManagement/Done.vue";
import { getTask } from "@/api/taskManagement/taskManagement.js";
import moment from "moment";
export default defineComponent({
  components: {
    Base,
    CarryOut,
    Done,
  },
  data() {
    return {
      //从路由传过来的id获取到的本条数据
      formState: {},
      extraList: [],
    };
  },
  methods: {
    async getData() {
      let res: any = await getTask(this.$route.params.id);
      this.formState = res.data.data;
      this.extraList =
        JSON.parse((this as any).formState.performUrlParam) || [];
    },
    computedtaskDimension(taskDimension) {
      switch (taskDimension) {
        case "1":
          return "不限区域";
        default:
          break;
      }
    },
    cancel() {
      history.back();
    },
  },
  mounted() {
    this.getData();
  },
});
